import Cookies from 'js-cookie';

(function($){

    const UBCookieBar = {
    
        init: function() {
    
            let consent = Cookies.get('CookieConsent');
    
            if( ! consent ) {

                jQuery('.ub-cookie-bar').show();
    
                if( gtag && typeof gtag === 'function' ) {
    
                    gtag('consent', 'default', {
                        'ad_storage': 'denied',
                        'ad_user_data': 'denied',
                        'ad_personalization': 'denied',
                        'analytics_storage': 'denied',
                        'wait_for_update': 500
                    });
    
                }
    
            }
    
        },
    
        consent: function( consent ) {
    
            Cookies.set('CookieConsent', consent, { expires: 30 });
    
            if( gtag && typeof gtag === 'function' ) {
    
                let acceptAds       = jQuery('#ub-gdpr-acca').is(':checked') ? 'granted' : 'denied';
                let acceptAnalytics = jQuery('#ub-gdpr-accan').is(':checked') ? 'granted' : 'denied';
    
                gtag('consent', 'update', {
                    'ad_user_data': acceptAds,
                    'ad_personalization': acceptAds,
                    'ad_storage': acceptAds,
                    'analytics_storage': acceptAnalytics
                });
                
    
            }
        
            jQuery('.ub-cookie-bar').fadeOut().remove();
        
            setTimeout(function(){
                location.reload(true);
            }, 1000)
        
        }
    
    }
    
    jQuery(document).ready(function() {
    
        UBCookieBar.init();

        jQuery('body').on('click', '.ub-cookie-bar button', function(evt) {
            evt.preventDefault();
            UBCookieBar.consent( jQuery(this).attr('data-consent') );
        });
    
    });
    
    })(jQuery);